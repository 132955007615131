import React, {useEffect} from 'react';
import { Helmet } from 'react-helmet';
import './Services.scss';
import CarouselDesk from '../../components/CarouselDesk/CarouselDesk';
import ConnectivitySolutions from '../../components/ConnectivitySolutions/ConnectivitySolutions';
import ManagedSolutions from '../../components/ManagedSolutions/ManagedSolutions';
import GetInTouch from '../../components/GetInTouch/GetInTouch';

export default function Services() {

  useEffect(() => {
    window.scroll(0,0);
  }, [])

  return (
    <div className="services">
      <Helmet>
        <title>Global Network Connectivity Services - inte-QT</title>
        <meta name="title" content="Global Network Connectivity Services - inte-QT"/>
        <meta property="og:title" content="Global Network Connectivity Services - inte-QT" />
        <meta
            property="twitter:title"
            content="Global Network Connectivity Services - inte-QT"
            data-react-helmet="True"
        />
        <meta
            name="description"
            content="Explore inte-QT’s Global Network Connectivity Services & Managed IT Solutions for enterprises worldwide. Reliable, fast, and secure connectivity for your business."
        />
        <meta
            property="og:description"
            content="Explore inte-QT’s Global Network Connectivity Services & Managed IT Solutions for enterprises worldwide. Reliable, fast, and secure connectivity for your business."
        />
        <meta
            property="twitter:description"
            content="Looking for a global network connectivity service supplier? We offer network connectivity solutions for worldwide enterprises."
            data-react-helmet="True"
        />
        <link rel="canonical" href="https://www.inte-qt.com/services" />
        <meta property="og:url" content="https://www.inte-qt.com/services"/>
        <meta property="twitter:url" content="https://www.bitsnbyteglobal.com/services"/>
        <meta
            name="keywords"
              content="network connectivity solutions, global network connectivity, network connectivity service supplier, enterprise network connectivity"
              data-react-helmet="True"/>
      </Helmet>

      <CarouselDesk />
      <div className="services__colorGradient"></div>
      <h2 className="text-center services__header mx-auto center-header blue">
        Global Network Connectivity Solutions
      </h2>
      <div className="b_read"><p className="text-center my-3">At inte-QT, we deliver high-performance, secure, and scalable global network connectivity solutions designed to meet the diverse needs of enterprises. Our services provide a direct connection to internet backbones and gateways in specific countries, ensuring minimal latency and high reliability.</p></div>

      <ConnectivitySolutions />

      <h2 className="text-center services__header mx-auto center-header blue">
        Global Managed IT Solutions
      </h2>
      <div className="b_read"><p className="text-center my-3">Our Global Managed IT Solutions empower enterprises to maintain control over their network strategies while ensuring the security and reliability of their IT infrastructure. Our certified network specialists provide around-the-clock monitoring, maintenance, and rapid resolution of any network issues.</p></div>
      
      <ManagedSolutions />
      <GetInTouch />
      </div>
  );
}
