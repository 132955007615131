import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import CarouselIndu from '../../../components/CarouselIndu/CarouselIndu';
import ConnectivitySolutions from '../../../components/ConnectivitySolutions/ConnectivitySolutions';
import GetInTouch from '../../../components/GetInTouch/GetInTouch';
import TestimonialCarousel from '../../../components/TestimonialCarousel/TestimonialCarousel';
import './DedicatedLines.scss';

export default function DedicatedLines() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <div className="dl">
      <Helmet>
        <title>Dedicated Lines - inte-QT</title>
        <meta name="title" content="Dedicated Lines - inte-QT" />
        <meta
          name="description"
          content="A dedicated line is a fibre/copper/radio connection between two points, one point is the end user location. It is symmetrical, so will have fast, equal upload and download speeds."
        />
        <link rel="canonical" href="https://www.inte-qt.com/services/dedicated-lines" />
      </Helmet>
      <img
        src="https://i.imgur.com/nc1zEeK.jpg"
        alt="12433124"
        className="banner-img banner-img__black"
      />
      <h1 className="text-center banner-img__header">
        Dedicated Lines
      </h1>
      <div className="container-fluid">
        <div className="container" align="justify"><div className="b_read">
          <  p className="dl__subtitle text-center">
              High Bandwidth point to point connectivity with unmatched global
              presence, best suitable for Enterprises, Carriers and Integrators.
            </p>
            <h3 className="dl__q">
              What is Dedicated Line (DIA) and it’s Importance in business growth?
            </h3>
            <p>
              A dedicated line is a fibre/copper/radio connection between two
              points, one point is the end user location. It is symmetrical, so
              will have fast, equal upload and download speeds.
            </p>
            <p>
              It's uninterrupted connection with dedicated speed with ultra-low
              latency,direct to businesses just like a pipe that can carry many
              things, a leased line can be used to carry a variety of data
              traffic, VPN (Virtual Private Network) traffic over WAN or LAN,
              phone calls; and VoIP (Voice over Internet Protocol).
            </p>
            <h3 className="dl__q">
              Global coverage across Tier 1, Tier2, Tier3 cities and far-flung
              areas:
            </h3>
            We have relations with carriers and managed services partners in more than <strong>190+ countries</strong> across the world, which gives us unmatched presence to
            provide dedicated internet access (DIA) in remote areas with multiple
            bandwidth capabilities with stringent SLA's.
            <h3 className="dl__q">
              Network that is dedicated solely to your needs:
            </h3>
            <ol>
              <li>
                <p>
                  Packages up to <strong>10Gb</strong>, all with totally uncontended Bandwidth.
                </p>
              </li>
              <li>
                <p>Static IP on WAN and LAN side, as per customer’s needs.</p>
              </li>
              <li>
                <p>
                  Dedicated Customer premise equipment (CPE) covering hardware
                  guarantee.
                </p>
              </li>
              <li>
                <p>
                  Tested guaranteed bandwidth, low latency and minimal packet
                  loss.
                </p>
              </li>
              <li>
                <p>
                  Dedicated <strong>24*7*365</strong> customer support with Proactive monitoring by
                  our NSOC team.
                </p>
              </li>
            </ol>
            <h3 className="dl__q">What makes inte-QT unique?</h3>
            <ol>
              <li>
                <p>Unmatched Global presence with Local expertise.</p>
              </li>
              <li>
                <p>
                  Tailor made solutions with fully flexible contract and terms.
                </p>
              </li>
              <li>
                <p>Low delivery lead times.</p>
              </li>
              <li>
                <p>
                  Dedicated NSOC team support with proactive monitoring <strong>24*7*365</strong>.
                </p>
              </li>
            </ol>
          </div>
        </div>
      </div>
      <div className="dl__space">
        <ConnectivitySolutions />
      </div>
      <div className="my-5 g-5">
        <h2 className="mt-5 text-center center-header mx-auto">
          We help not one, but many companies.
        </h2>
        <CarouselIndu />
      </div>
      <TestimonialCarousel />
      <GetInTouch />
    </div>
  );
}
