import React, { useEffect } from 'react';
import PartnerTimeline from '../../components/PartnerTimeline/PartnerTimeline';
import './PartnerCenter.scss';
import { Link } from 'react-router-dom';
import TestimonialCarousel from '../../components/TestimonialCarousel/TestimonialCarousel';
import PartnerBenefits from '../../components/PartnerBenefits/PartnerBenefits';
import { Helmet } from 'react-helmet';
import GetInTouch from '../../components/GetInTouch/GetInTouch';

export default function PartnerCenter() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <div className="partners">
      <Helmet>
        <title>Global Network Connectivity Business Collaboration</title>
        <meta name="title" content="Global Network Connectivity Business Collaboration"/>
        <meta property="og:title" content="Global Network Connectivity Business Collaboration" />
        <meta
            property="twitter:title"
            content="Global Network Connectivity Business Collaboration"
            data-react-helmet="True"
        />
        <meta
            name="description"
            content="We are looking for a business partner to collaborate with our business and enhance our portfolio of global network connectivity services everywhere in the world."
        />
        <meta
            property="og:description"
            content="We are looking for a business partner to collaborate with our business and enhance our portfolio of global network connectivity services everywhere in the world."
        />
        <meta
            property="twitter:description"
            content="We are looking for a business partner to collaborate with our business and enhance our portfolio of global network connectivity services everywhere in the world."
            data-react-helmet="True"
        />
        <link rel="canonical" href="https://www.inte-qt.com/partner-center" />
        <meta property="og:url" content="https://www.inte-qt.com/partner-center"/>
        <meta property="twitter:url" content="https://www.bitsnbyteglobal.com/partner-center"/>
        <meta
            name="keywords"
              content="global business partner, business collaboration, global network connectivity, network connectivity services"
              data-react-helmet="True"/>
      </Helmet>
      <img
        src="https://i.imgur.com/5Q7OgtF.jpg"
        alt="1"
        className="partners__img"
      />
      <h1 className="center-header text-center mx-auto partners__title">
        Become a Partner in Global Network Connectivity Solutions
      </h1>
      
      <div className="container partners__writer">
        <div className="row">
          <div className="col-lg-6 col-md-12 ">
            <p align="justify">
              Collaboration is the core of our business and to enhance our
              portfolio of services everywhere in the world, it is important to
              have more and more partners, and the right partners. We aim to
              partner with every local and global supplier with their set of
              qualities to deliver a wide range of solutions to our customers.
            </p>
          </div>

          <div className="col-lg-6 col-md-12 partners__box">
            {' '}
            <p align="justify">
              {' '}
              We tend to create a mix of our services and our partner services
              for our customers on our portal to deliver end to end, quote to
              cash to assurance for any Global internet connectivity solutions,
              managed services and networking solutions.{' '}
            </p>
          </div>
        </div>
      </div>
      <div className="text-center container mt-5">
        <div className="b_read">
          <p className="partners__center">
            "We are enhancing our partner portfolio and our team is constantly
            looking for the providers offering Internet connectivity, managed
            services and networking solutions" <br /> GPM Head, inte-QT
          </p>
        </div>
      </div>

      <h2 className="center-header text-center mx-auto partners__title">
        Key Benefits of Partnering with Us
      </h2>
      <PartnerBenefits />
      <h2 className="center-header text-center mx-auto partners__title">
        Our Partner Program & Opportunities
      </h2>
      <PartnerTimeline />
      <div className="text-center partners__contact">
        <h2 className="center-header partners__contact-title mx-auto">
          {' '}
          Get in Touch to Become a Partner 
        </h2>
        <Link className="button m-5" to="/contact/partners">
          Contact Us Now
        </Link>
      </div>
      <TestimonialCarousel />
      <GetInTouch />
    </div>
  );
}
